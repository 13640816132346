import {
  FetchFinProductCategoriesFilters,
  FetchProductCategoriesCacheKey
} from '../../productsTypes';
import {
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../categories/categoriesTypes';
import { FETCH_CACHE_PRODUCT_CATEGORIES_QUERY } from '../../queries/fetchCacheProductCategories.query';

import {
  FETCH_PRODUCT_CATEGORIES_QUERY,
  FetchProductCategoriesQueryResponse
} from '../../queries/fetchProductCategories.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.PRODUCT_GROUPS }
};
const initialSort = [FetchCategoriesSortTypes.NAME_ASC];
const options = {
  staleTime: 1000 * 60 * 60,
  withoutPrefetch: true
};

interface ProductCategoriesOptions {
  cacheKey?: FetchProductCategoriesCacheKey;
  addInitialFilters?: FetchFinProductCategoriesFilters;
}

function useProductCategories({
  cacheKey,
  addInitialFilters
}: ProductCategoriesOptions = {}) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchProductCategoriesQueryResponse>({
    action: CategoriesPermissions.READ_PRODUCT_CATEGORIES_INDEX_ES_QUERY,
    cacheKey,
    query: FETCH_PRODUCT_CATEGORIES_QUERY,
    cacheQuery: FETCH_CACHE_PRODUCT_CATEGORIES_QUERY,
    initialFilters,
    initialSort,
    initialLimit: 1000,
    options,
    trackTotalHits: true
  });

  return {
    productCategories: categories,
    productCategoriesErrorMessage: categoriesError,
    productCategoriesFetched: categoriesFetched,
    productCategoriesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useProductCategories;
