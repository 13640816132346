import { gql } from 'graphql-request';
import { ProductID, ProductUUID } from '../productsTypes';
import { CategoryID } from '../../categories/categoriesTypes';

export interface CacheFetchProductsAggregationsQueryResponse {
  id: ProductID;
  uuid: ProductUUID;
}

interface AggregationsItem {
  id: CategoryID;
}

export interface CacheFetchProductsAggregationsQueryResponseAggregations {
  brands?: AggregationsItem[];
  categories?: AggregationsItem[];
  colors?: AggregationsItem[];
  custom_tags?: AggregationsItem[];
  materials?: AggregationsItem[];
  parent_categories?: AggregationsItem[];
  seasonal?: AggregationsItem[];
  styles?: AggregationsItem[];
  types?: AggregationsItem[];
}

export const CACHE_FETCH_PRODUCTS_AGGREGATIONS_QUERY = gql`
  query CacheProductsAggregations(
    $filters: ProductsFilters
    $sort: [ProductsSortEnum!]
    $limit: Int
    $offset: Int
    $source: String
    $trackTotalHits: Boolean
    $aggregations: Boolean
  ) {
    products(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
      source: $source
      trackTotalHits: $trackTotalHits
      aggregations: $aggregations
    ) {
      aggregations
      nodes {
        id
        uuid
      }
      paginationInfo {
        currentPage
        nextPage
        totalCount
      }
      sql
    }
  }
`;
