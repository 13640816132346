import { gql } from 'graphql-request';
import {
  ProductID,
  ProductName,
  ProductUUID,
  ProductImageUUID,
  ProductImageFile,
  ProductNanoID,
  ProductImageID
} from '../productsTypes';

export interface FetchSelectProductsQueryResponse {
  id: ProductID;
  uuid: ProductUUID;
  nanoId: ProductNanoID;
  name: ProductName;
  image: {
    id: ProductImageID;
    uuid: ProductImageUUID;
    file: ProductImageFile;
  };
}

export const FETCH_SELECT_PRODUCTS_QUERY = gql`
  query Products(
    $filters: ProductsFilters
    $sort: [ProductsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    products(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        nanoId
        createdAt
        name
        image {
          id
          uuid
          file
        }
      }
      paginationInfo {
        currentPage
        nextPage
        totalCount
      }
      sql
    }
  }
`;
