import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import { FetchProductsFilters } from '../../productsTypes';

import {
  CACHE_FETCH_PRODUCTS_AGGREGATIONS_QUERY,
  CacheFetchProductsAggregationsQueryResponse,
  CacheFetchProductsAggregationsQueryResponseAggregations
} from '../../queries/fetchCacheProductsAggregations.query';

import { FETCH_SELECT_PRODUCTS_QUERY } from '../../queries/fetchSelectProducts.query';

import { useCacheProducts } from '../useCacheProducts';
import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { ProductCache } from '../../ProductCache';
import { ProductsPermissions } from '../../productsConstants';

type useCacheProductsAggregationsOptions = {
  productsFilters: FetchProductsFilters;
};

function useCacheProductsAggregations({
  productsFilters
}: useCacheProductsAggregationsOptions) {
  const currentUser = useCurrentUser();

  const withSearchProducts = currentUser.hasPermissions(
    ProductsPermissions.READ_PRODUCTS_AGGREGATIONS
  );

  const {
    productsAggregations,
    productsFetched,
    productsLoading,
    filterProducts: changeProductsAggregationsFilter,
    productsFilters: productsAggregationsFilters
  } = useCacheProducts<
    CacheFetchProductsAggregationsQueryResponse,
    unknown,
    CacheFetchProductsAggregationsQueryResponseAggregations
  >({
    additionalParams: { aggregations: true },
    cacheKey: ProductCache.aggregationsProductsCacheKey(),
    cacheQuery: CACHE_FETCH_PRODUCTS_AGGREGATIONS_QUERY,
    initialFilters: productsFilters,
    query: FETCH_SELECT_PRODUCTS_QUERY,
    trackTotalHits: true,
    options: {
      enabled: withSearchProducts,
      enabledPlaceholder: false
    }
  });

  useEffect(() => {
    if (
      withSearchProducts &&
      !isEqual(productsFilters, productsAggregationsFilters)
    ) {
      changeProductsAggregationsFilter({
        ...productsFilters
      });
    }
  }, [
    productsFilters,
    productsAggregationsFilters,
    changeProductsAggregationsFilter,
    withSearchProducts
  ]);

  return {
    productsAggregations: withSearchProducts ? productsAggregations : {},
    productsAggregationsFetched:
      !withSearchProducts || (productsFetched && !productsLoading)
  };
}

export default useCacheProductsAggregations;
