import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryName,
  CategoryLocalizedName,
  CategoryParentID,
  CategoryParentUUID,
  CategoryParentName,
  CategoryParentLocalizedName,
  CategoryImageID,
  CategoryImageFile,
  CategoryNameEn,
  CategoryParentNameEn
} from '../../categories/categoriesTypes';

export interface FetchProductCategoriesQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  image: {
    id: CategoryImageID;
    file: CategoryImageFile;
  };
  name: CategoryName;
  nameEn: CategoryNameEn;
  localizedName: CategoryLocalizedName;
  parent?: {
    id: CategoryParentID;
    image: {
      id: CategoryImageID;
      file: CategoryImageFile;
    };
    uuid: CategoryParentUUID;
    name: CategoryParentName;
    nameEn: CategoryParentNameEn;
    localizedName: CategoryParentLocalizedName;
  } | null;
}

export const FETCH_PRODUCT_CATEGORIES_QUERY = gql`
  query ProductCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        image {
          id
          file
        }
        name
        nameEn
        localizedName
        parent {
          image {
            id
            file
          }
          name
          nameEn
          id
          uuid
          localizedName
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
