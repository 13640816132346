import { useMemo } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';

import {
  FetchProductsFilters,
  ProductClientID
} from '../../../products/productsTypes';
import { ThreeDStockIndexPageProductsCategoriesNavCategories } from '../../components/menus/ThreeDStockIndexPageProductsCategoriesNav/ThreeDStockIndexPageProductsCategoriesNav.types';

import { FetchProductCategoriesQueryResponse } from '../../../products/queries/fetchProductCategories.query';

import { useProductCategories } from '../../../products/hooks/useProductCategories';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';
import { useCacheProductsAggregations } from '../../../products/hooks/useCacheProductsAggregations';

import { ProductCache } from '../../../products/ProductCache';

interface ThreeDStockProductsCategoriesOptions {
  clientIds?: ProductClientID[];
  productsFilters?: FetchProductsFilters;
}

function useThreeDStockProductsCategories({
  productsFilters
}: ThreeDStockProductsCategoriesOptions) {
  const {
    productCategories: productCategoriesWithParents,
    productCategoriesErrorMessage: allProductCategoriesErrorMessage,
    productCategoriesFetched: allProductCategoriesFetched,
    productCategoriesIsPlaceholderData: allProductCategoriesIsPlaceholderData
  } = useProductCategories({
    cacheKey: ProductCache.categoriesCacheKey()
  });

  const { productsAggregations, productsAggregationsFetched } =
    useCacheProductsAggregations({
      productsFilters
    });

  useShowToastOnErrorChange({
    error: allProductCategoriesErrorMessage
  });

  const productAggregationsCategories = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    if (isEmpty(productsAggregations)) {
      return productCategoriesWithParents;
    }

    const availableCategories = concat(
      productsAggregations?.categories,
      productsAggregations?.parent_categories
    );

    return productCategoriesWithParents?.filter((category) =>
      availableCategories?.some(
        (availableCategory) => availableCategory.id === category.id
      )
    );
  }, [productCategoriesWithParents, productsAggregations]);

  const parentCategories =
    useMemo<ThreeDStockIndexPageProductsCategoriesNavCategories>(() => {
      const parentCategories = sortBy(
        filter(productCategoriesWithParents, (category) => !category.parent),
        'localizedName'
      );

      if (isEmpty(productsAggregations)) {
        return parentCategories;
      }

      const availableCategories = productsAggregations?.parent_categories;

      return parentCategories?.filter((category) =>
        availableCategories?.some(
          (availableCategory) => availableCategory.id === category.id
        )
      );
    }, [productCategoriesWithParents, productsAggregations]);

  return {
    productCategoriesWithParents,
    parentCategories,
    productAggregationsCategories,
    productCategoriesErrorMessage: allProductCategoriesErrorMessage,
    productCategoriesFetched:
      allProductCategoriesFetched && productsAggregationsFetched,
    productCategoriesIsPlaceholderData: allProductCategoriesIsPlaceholderData
  };
}

export default useThreeDStockProductsCategories;
